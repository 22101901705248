import React, { useState } from "react";
import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import phoneInput from "@/public/images/phoneInput.svg";
import Image from "next/image";
import passwordInput from "@/public/images/passwordInput.svg";
import { Eye, EyeOff } from "lucide-react";
const index = ({
  type,
  name,
  placeholder,
  value,
  FormikProps,
  iconName,
  required = false,
  maxLength,
  label,
  disabled = false,
  typeNumber = undefined,
  fullWidth = false,
  className = "",
  eye = false,
  ...props
}: {
  type: string;
  name: string;
  placeholder: string;
  value?: string;
  FormikProps?: FormikProps<any>;
  iconName?: string;
  required?: boolean;
  maxLength?: number;
  label?: string;
  disabled?: boolean;
  typeNumber?: "pound" | "percentage" | undefined;
  fullWidth?: boolean;
  className?: string;
  eye?: boolean;
  [key: string]: any;
}) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`relative ${fullWidth ? "w-full" : ""} ${
        iconName === "phone"
          ? "after:content-[url('/images/phoneInput.svg')] after:absolute after:top-[20px] after:right-[10px]  "
          : iconName === "password"
          ? "after:content-[url('/images/passwordInput.svg')] after:absolute after:top-[20px] after:right-[10px]  "
          : iconName === "addFile"
          ? "after:content-[url('/images/addFile.svg')] after:absolute after:top-[12px] after:right-[10px]  "
          : ""
      } ${className} `}
    >
      {label && <p className=" text-16 text-white mb-2">{label}</p>}
      <div
        className={`flex justify-between items-center bg-[#474A4D] rounded-[10px] ${
          fullWidth ? "w-full" : ""
        }`}
      >
        {!eye && (
          <Field
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            className={` text-right placeholder:text-right h-[60px] bg-[#474A4D] py-[15px] px-[10px] rounded-[10px] text-white text-16  placeholder:text-textColor placeholder:text-13 relative outline-none block w-full after:content-['dfdf'] after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0 after:bg-[#474A4D]  ${
              FormikProps?.errors[name] && FormikProps?.touched[name]
                ? "border border-red-500"
                : FormikProps?.values[name]
                ? "border border-primaryColor"
                : "border border-[#474A4D]"
            }  ${iconName !== undefined ? "pr-[50px]" : ""}`}
            // required={required}
            // maxLength='5'
            disabled={disabled}
            {...props}
          />
        )}
        {eye && (
          <Field
            type={show ? "text" : "password"}
            name={name}
            placeholder={placeholder}
            value={value}
            className={` text-right placeholder:text-right h-[60px] bg-[#474A4D] py-[15px] px-[10px] rounded-[10px] text-white text-16  placeholder:text-textColor placeholder:text-13 relative outline-none block w-full after:content-['dfdf'] after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0 after:bg-[#474A4D]  ${
              FormikProps?.errors[name] && FormikProps?.touched[name]
                ? "border border-red-500"
                : FormikProps?.values[name]
                ? "border border-primaryColor"
                : "border border-[#474A4D]"
            }  ${iconName !== undefined ? "pr-[50px]" : ""}`}
            // required={required}
            // maxLength='5'
            disabled={disabled}
            {...props}
          />
        )}
        {eye && !show && (
          <Eye
            className="cursor-pointer text-white mx-2"
            onClick={() => setShow(true)}
          />
        )}
        {eye && show && (
          <EyeOff
            className="cursor-pointer text-white mx-2"
            onClick={() => setShow(false)}
          />
        )}
        {typeNumber && (
          <span className="text-textColor text-12  px-2">
            {typeNumber === "pound" && "جنيه"}
            {typeNumber === "percentage" && "%"}
          </span>
        )}
      </div>
      <ErrorMessage name={name} component="div" className="text-[#F75555]" />
    </div>
  );
};

export default index;
